* {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0px;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

@media only screen and (min-width: 1024px) {
  html,
  body {
    font-family: Roboto;
    background: rgb(55, 135, 183);
    background: linear-gradient(
      135deg,
      rgba(55, 135, 183, 1) 0%,
      rgba(212, 40, 71, 1) 73%,
      rgba(169, 35, 117, 1) 100%
    );
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  html,
  body {
    font-family: Roboto;
    background: rgb(55, 135, 183);
    background: linear-gradient(
      135deg,
      rgba(55, 135, 183, 1) 0%,
      rgba(212, 40, 71, 1) 73%,
      rgba(169, 35, 117, 1) 100%
    );
    font-size: 22px;
  }
}

.login-page {
  padding: 5% 0 5%;
  margin: 0 auto 100px;
  max-width: 360px;
  position: relative;
}

.login-form {
  box-shadow: 0 0 27px 0 rgba(84, 84, 84, 0.62);
  background-color: lightgray !important;
  border-radius: 25px;
}

.login-form-header {
  padding-top: 16px;
}

.login-from-row {
  padding-top: 16px;
  padding-bottom: 16px;
}

.login-form-font-header {
  font-size: 30px;
  font-weight: bold;
}

.login-form-font-header span {
  color: #007c9b;
}

.react-select > div {
  margin: -1px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.mdb tbody tr td {
  text-align: center;
  font-size: 14px;
}
.mdb thead {
  text-align: center;
  font-size: 16px;
}

.online {
  text-decoration: none;
  cursor: pointer;
  color: #98012e;
  position: absolute;
  top: 9px;
  right: 15px;
  font-weight: bolder;
}
.pmsc-filter {
  width: 96%;
  height: 30px;
  position: relative;

  margin: 0 2%;
}
.pmsc-left ul {
  display: block;

  margin: 0 2%;
  padding: 0;

  width: 96%;
  max-height: calc(100% - 30px);

  border: solid 1px #e2e2e2;
  border-top: none;

  list-style-type: none;

  overflow-x: hidden;
  overflow-y: scroll;
}

.pmsc-left ul li {
  width: 77%;

  margin: 0;
  padding: 8px 20% 14px 3%;

  border-bottom: solid 1px #e2e2e2;

  position: relative;

  cursor: pointer;
}

.pmsc-left ul li.activ,
.pmsc-left ul li:hover {
  background-color: #ededed;
}

.pmsc-left ul li:last-child {
  border-bottom: none;
}

.pmsc-f-i,
.pmsc-f-b {
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;

  border: none;

  display: inline-block;

  vertical-align: top;
}

.pmsc-f-i {
  width: 90%;
  height: 29px;

  left: 0;

  font-family: "Exo";
  font-size: 16px;
  line-height: 29px;
  outline: none;
  border: none;

  border-bottom: solid 1px #98012e;
  border-left: none;
  border-top: none;
  border-right: none;

  padding: 5px;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pmsc-f-b {
  width: calc(10% + 2px);
  height: 30px;

  right: -2px;

  background-color: #98012e;

  color: white;
}

.pmsc-f-b i {
  font-size: 20px;
  line-height: 29px;
}

.pmsc-loc {
  position: absolute;

  color: #98012e;

  right: 7%;
  top: calc(50% - 17px);
}

.pmsc-loc i {
  font-size: 35px;
  line-height: 35px;

  transition: all 0.1s ease-out;
}

.pmsc-left ul li.activ .pmsc-loc i {
  font-size: 55px;
  line-height: 55px;

  margin: -12px -8px 0 0;
}

.pmsc-title {
  font-size: 16px;
}

.pmsc-address {
  font-size: 10px;

  padding: 9px 0;

  color: #707070;
}

.pmsc-phone {
  font-size: 12px;
}

.pmsc-phone a i,
.pmsc-phone span i {
  font-size: 13px;

  margin: 0;
  padding: 0;

  line-height: 12px;
}

.pmsc-phone span i {
  font-size: 22px;

  margin: 0;
  padding: 0;

  line-height: 12px;

  display: inline-block;
}

.pmsc-phone a,
.pmsc-phone span {
  display: inline-block;
  background-color: #98012e;

  vertical-align: middle;

  padding: 4px 12px;
  margin: 0 6px 0 0;

  color: #fff;

  text-decoration: none;

  -webkit-box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.pmsc-phone span {
  /*display: none;*/

  margin: 0;

  line-height: 0px;
  padding: 8px 11px;
}
.pmsc-phone a:hover,
.pmsc-phone span:hover {
  background-color: #e10045;
}
.pmsc-phone a {
  margin-bottom: 1em;
}

.container1 {
  position: relative;
  width: 50%;
}

.image1 {
  display: block;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.overlay1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 90%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 30px;
  //background-color: #008CBA;
  opacity: 0.8;
}

.container1:hover .overlay1 {
  opacity: 1;
}

.text1 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
